export type BreakdownItemFormField = {
  id?: string
  uid: string
  name: string
}

export type BreakdownType = 'primary' | 'secondary' | 'tertiary'
export type DemandPlanStatus = 'draft' | 'active'

export type BreakdownFormField = {
  id?: string
  uid: string
  name: string
  breakdownType: BreakdownType

  breakdownItems: BreakdownItemFormField[]
}

export type DemandYearlySpreadField = {
  id?: string
  q1: number
  q2: number
  q3: number
  q4: number
}

export type MonthlySpread = {
  id?: string
  month: number
  percentage: number
}

export type DemandSpreadField = {
  id?: string
  spreadPercent: number
  value: number
}

export type DemandFunnelSpreadAssumptionField = {
  id?: string
  conversion: number
  count: number
}

export type DemandFunnelSpreadField = {
  id?: string
  spreadPercent: number
  value: number
  averageDealSize: number
  assumptions: {
    // funnel item uid
    [key: string]: DemandFunnelSpreadAssumptionField
  }
}

export type DemandFunnelItemFormField = {
  id?: string
  uid: string
  name: string
  description: string
}

export type DemandFunnelFormField = {
  id?: string
  uid: string
  name: string
  funnelItems: DemandFunnelItemFormField[]
}

// Key of the demand funnel
export type FunnelSpread = Record<string, DemandFunnelSpreadField>

export type FormValuesProps = {
  id?: string
  name: string
  description: string
  startDate: Date
  endDate: Date
  targetName: string
  targetValue: number
  status: DemandPlanStatus

  monthlySpreads: MonthlySpread[]

  breakdowns: BreakdownFormField[]
  spreads: { [key: string]: DemandSpreadField }
  funnels: DemandFunnelFormField[]

  // Key of the demand spread or "demandPlan" for top level
  funnelSpreads: Record<string, FunnelSpread>
  selectedFunnelSpread?: string
}

export type SelectOption = {
  id: string
  name: string
}

export enum ChartTypes {
  Bars = 'Bars',
  StackedBars = 'Stacked Bars',
  Lines = 'Lines',
  Funnel = 'Funnel',
}

export type DemandPlanSearchQueryParams = {
  // Table params
  fv?: boolean // funnel process visibility toggle
  av?: boolean // show actuals checkbox
  bv?: string // breakdown visibility toggles
  bf?: string // breakdown filter
  ff?: string[] // funnel filter
  ai?: 0 | 1 // active tab index
  st?: 'q' | 'm' // spread type
  // Chart params
  ct?: ChartTypes // chart type
  cbf?: string // chart breakdown filter
  cff?: string[] // chart funnel filter
  cav?: 0 | 1 // chart actuals
  csf?: string // chart selected funnel
  csb?: string // chart stacked by
  csm?: string // chart selected metric
  cfs?: string // chart funnel stage
}

export type FunnelSourceCode = 'all' | 'marketing' | 'sales' | 'partner'
